/* Small Mobile Devices */
@media (max-width: 480px) {
    .banner {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        position: relative;
        margin-top: 20%;
        max-width: 90vw;
        max-height: 70vh;
        margin: 28% auto 0 auto;
        z-index: 1;
    }
    .banner-box {
        background-color: rgba(0, 0, 0, 0.75);
        padding: 20px 15px;
        border-radius: 12px;
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.6);
        opacity: 0;
        animation: fadeInBanner 1s ease-out forwards;
        animation-delay: 1s;
        max-height: 75vh;
        max-width: 98vw;
        z-index: 2;
        overflow-y: scroll;
        scrollbar-width: none;
    }
    .feature-list {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-bottom: 16px;
        gap: 10px;
        color: #d3d3d3;
    }
    
    .feature-item {
        background: rgba(0, 0, 0, 0.5);
        padding: 10px;
        border-radius: 8px;
        text-align: left;
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: small;
        min-width: 1vw;
        max-width: 90vw;
    }
    
    .feature-item span {
        font-weight: bold;
        color: #ffdd57;
        text-align: left;
        min-width: 40vw;
        max-width: 50vw;
        
    }
    .title {
        font-size: clamp(16px, 4vw, 20px);
        margin-bottom: 8px;
        color: #fff;
        text-align: center;
    }
    
    .subtitle {
        font-size: clamp(14px, 2vw, 16px);
        color: #e0b96a;
        margin-bottom: 16px;
        text-align: center;
    }
    
    .description, .launch {
        font-size: clamp(13px, 2.5vw, 15px);
        margin-bottom: 16px;
        text-align: center;
        color: #fff;
    }
    .title-landscape {
        font-size: 20px;
        margin-bottom: 0px;
        color: #fff;
    }
    
    .subtitle-landscape {
        font-size: 16px;
        color: #e0b96a;
        margin-bottom: 0px;
    }
    
    .description-landscape, .launch-landscape {
        font-size: clamp(14px, 2.5vw, 16px);
        margin-bottom: 12px;
        text-align: center;
        color: #fff;
    }
}

@media (min-width: 481px) and (max-width: 768px) {
    /* Banner Placement */
    .banner {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        position: relative;
        margin-top: 20%;
        max-width: 90vw;
        max-height: 70vh;
        margin: 28% auto 0 auto;
        z-index: 1;
    }
    .banner-box {
        background-color: rgba(0, 0, 0, 0.75);
        padding: 20px 15px;
        border-radius: 12px;
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.6);
        opacity: 0;
        animation: fadeInBanner 1s ease-out forwards;
        animation-delay: 1s;
        max-height: 75vh;
        max-width: 98vw;
        z-index: 2;
        overflow-y: scroll;
        scrollbar-width: none;
    }
    .feature-list {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-bottom: 16px;
        gap: 10px;
        color: #d3d3d3;
    }
    
    .feature-item {
        background: rgba(0, 0, 0, 0.5);
        padding: 10px;
        border-radius: 8px;
        text-align: left;
        display: flex;
        align-items: center;
        gap: 10px;
    }
    
    .feature-item span {
        font-weight: bold;
        color: #ffdd57;
        text-align: left;
    }
    .title {
        font-size: clamp(16px, 4vw, 20px);
        margin-bottom: 8px;
        color: #fff;
        text-align: center;
    }
    
    .subtitle {
        font-size: clamp(14px, 2vw, 16px);
        color: #e0b96a;
        margin-bottom: 16px;
        text-align: center;
    }
    
    .description, .launch {
        font-size: clamp(13px, 2.5vw, 15px);
        margin-bottom: 16px;
        text-align: center;
        color: #fff;
    }
    .banner-landscape {
        justify-content: center;
        display: flex;
        align-items: center;
        text-align: center;
        position: relative;
        margin: 10% auto 0 auto;
        z-index: 1;
    }
    .banner-box-landscape {
        background-color: rgba(0, 0, 0, 0.75);
        padding: 15px 10px;
        border-radius: 12px;
        text-align: center;
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.6);
        opacity: 0;
        animation: fadeInBanner 1s ease-out forwards;
        animation-delay: 1s;
        overflow-y: scroll;
        scrollbar-width: none;
        z-index: 2;
        max-height: 70vh;
        max-width: 90vw;
    }
    .feature-list-landscape {
        display: flex;
        align-items: flex-start; /* Align items to the top */
        flex-direction: column;
        gap: 2px;
        font-size: .9em;
        color: #d3d3d3;
    }
    .feature-item-landscape {
        background: rgba(0, 0, 0, 0.5);
        padding: 10px;
        border-radius: 8px;
        text-align: left;
        display: flex;
        align-items: center;
        gap: 10px;
        min-width: 1vw;
        max-width: 90vw;
    }
    
    .feature-item-landscape span {
        min-width: 40vw;
        max-width: 50vw;
        font-weight: bold;
        color: #ffdd57;
        text-align: left;
    }

    .title-landscape {
        font-size: 20px;
        margin-bottom: 0px;
        color: #fff;
    }
    
    .subtitle-landscape {
        font-size: 16px;
        color: #e0b96a;
        margin-bottom: 0px;
    }
    
    .description-landscape, .launch-landscape {
        font-size: clamp(14px, 2.5vw, 16px);
        margin-bottom: 12px;
        text-align: center;
        color: #fff;
    }
}

/* Tablet Landscape or Small Laptops */
@media (min-width: 769px) and (max-width: 1023.999px) {
    .banner {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        position: relative;
        /*margin-top: 10%;*/
        max-width: 90vw;
        max-height: 70vh;
        margin: 14% auto 0 auto;
        z-index: 1;
    }
    .banner-box {
        background-color: rgba(0, 0, 0, 0.75);
        padding: 20px 15px;
        border-radius: 12px;
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.6);
        opacity: 0;
        animation: fadeInBanner 1s ease-out forwards;
        animation-delay: 1s;
        max-height: 75vh;
        max-width: 98vw;
        z-index: 2;
        overflow-y: scroll;
        scrollbar-width: none;
    }
    .feature-list {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin-bottom: 16px;
        gap: 10px;
        font-size: 1em;
        color: #d3d3d3;
    }
    .feature-item {
        background: rgba(0, 0, 0, 0.5);
        padding: 10px;
        border-radius: 8px;
        text-align: left;
        display: flex;
        align-items: center;
        gap: 10px;
        min-width: 1vw;
        max-width: 90vw;
        font-size: large;
    }
    .feature-item span {
        font-weight: bold;
        font-size: large;
        color: #ffdd57;
        text-align: left;
        min-width: 44vw;
        max-width: 50vw;  
    }
    .title {
        font-size: clamp(24px, 4vw, 28px);
        margin-bottom: 8px;
        color: #fff;
        text-align: center;
    }
    
    .subtitle {
        font-size: clamp(20px, 2vw, 22px);
        color: #e0b96a;
        margin-bottom: 16px;
        text-align: center;
    }
    .description, .launch {
        font-size: clamp(17px, 2.5vw, 19px);
        margin-bottom: 16px;
        text-align: center;
        color: #fff;
    }
    .banner-landscape {
        justify-content: center;
        display: flex;
        align-items: center;
        text-align: center;
        position: relative;
        margin: 7% auto 0 auto;
        z-index: 1;
    }
    .banner-box-landscape {
        background-color: rgba(0, 0, 0, 0.75);
        padding: 15px 10px;
        border-radius: 12px;
        text-align: center;
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.6);
        opacity: 0;
        animation: fadeInBanner 1s ease-out forwards;
        animation-delay: 1s;
        overflow-y: scroll;
        scrollbar-width: none;
        z-index: 2;
        max-height: 72vh;
        max-width: 90vw;
    }
    .feature-list-landscape {
        display: flex;
        align-items: flex-start; /* Align items to the top */
        flex-direction: column;
        gap: 2px;
        font-size: 1em;
        color: #d3d3d3;
    }
    .feature-item-landscape {
        background: rgba(0, 0, 0, 0.5);
        padding: 10px;
        border-radius: 8px;
        text-align: left;
        display: flex;
        align-items: center;
        gap: 10px;
        min-width: 1vw;
        max-width: 90vw;
    }
    
    .feature-item-landscape span {
        min-width: 44vw;
        max-width: 50vw;
        font-weight: bold;
        color: #ffdd57;
        text-align: left;
    }

    .title-landscape {
        font-size: 20px;
        margin-bottom: 0px;
        color: #fff;
    }
    
    .subtitle-landscape {
        font-size: 16px;
        color: #e0b96a;
        margin-bottom: 0px;
    }
    
    .description-landscape, .launch-landscape {
        font-size: clamp(14px, 2.5vw, 16px);
        margin-bottom: 12px;
        text-align: center;
        color: #fff;
    }
}

/* Medium-Large Desktops */
@media (min-width: 1024px) and (max-width: 1440px) {
    .banner {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        position: relative;
        margin-top: 8%;
        max-width: 90vw;
        max-height: 60vh; /* Adjusted to avoid overflow */
        margin: 15% auto 0 auto;
        z-index: 1;
    }
    
    .banner-box {
        background-color: rgba(0, 0, 0, 0.75);
        padding: 15px 10px; /* Reduced padding */
        border-radius: 12px;
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.6);
        opacity: 0;
        animation: fadeInBanner 1s ease-out forwards;
        animation-delay: 1s;
        max-height: 65vh; /* Reduced max-height */
        max-width: 85vw;
        /*overflow-y: auto;*/
        overflow-y: scroll;
        scrollbar-width: none;
        z-index: 2;
    }

    .feature-list {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin-bottom: 10px;
        gap: 8px;
        font-size: 1.4em;
        color: #d3d3d3;
    }

    .feature-item {
        background: rgba(0, 0, 0, 0.5);
        padding: 8px;
        border-radius: 8px;
        text-align: left;
        display: flex;
        align-items: center;
        gap: 8px;
        min-width: 1vw;
        max-width: 85vw;
    }

    .feature-item span {
        font-weight: bold;
        color: #ffdd57;
        text-align: left;
        min-width: 30vw;
        max-width: 45vw;
    }

    .title {
        font-size: clamp(26px, 3.5vw, 28px);
        margin-bottom: 8px;
        color: #fff;
        text-align: center;
    }

    .subtitle {
        font-size: clamp(20px, 2vw, 22px);
        color: #e0b96a;
        margin-bottom: 12px;
        text-align: center;
    }

    .description, .launch {
        font-size: clamp(18px, 2.5vw, 20px);
        margin-bottom: 12px;
        text-align: center;
        color: #fff;
    }

    /* Landscape adjustments */
    .banner-landscape {
        justify-content: center;
        display: flex;
        align-items: center;
        text-align: center;
        position: relative;
        margin: 6% auto 0 auto;
        z-index: 1;
    }
    
    .banner-box-landscape {
        background-color: rgba(0, 0, 0, 0.75);
        padding: 15px 10px;
        border-radius: 12px;
        text-align: center;
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.6);
        opacity: 0;
        animation: fadeInBanner 1s ease-out forwards;
        animation-delay: 1s;
        /*overflow-y: auto;*/
        /*overflow-y: hidden;*/
        overflow-y: scroll;
        scrollbar-width: none; /* Hides scrollbar in Firefox */
        z-index: 2;
        max-height: 72vh;
        max-width: 85vw;
    }
    
    .feature-list-landscape {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin-bottom: 16px;
        gap: 8px;
        font-size: 1.4em;
        color: #d3d3d3;
    }
    
    .feature-item-landscape {
        background: rgba(0, 0, 0, 0.5);
        padding: 10px;
        border-radius: 8px;
        text-align: left;
        display: flex;
        align-items: center;
        gap: 8px;
        min-width: 1vw;
        max-width: 85vw;
    }
    
    .feature-item-landscape span {
        font-weight: bold;
        color: #ffdd57;
        text-align: left;
        min-width: 44vw;
        max-width: 45vw;
    }

    .title-landscape {
        font-size: clamp(26px, 3.5vw, 28px);
        margin-bottom: 8px;
        color: #fff;
        text-align: center;
    }
    
    .subtitle-landscape {
        font-size: clamp(22px, 2vw, 24px);
        color: #e0b96a;
        margin-bottom: 12px;
        text-align: center;
    }
    
    .description-landscape, .launch-landscape {
        font-size: clamp(18px, 2.5vw, 20px);
        margin-bottom: 12px;
        text-align: center;
        color: #fff;
    }
}

/* Extra-Large Screens */
@media (min-width: 1441px) {
    .banner {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        position: relative;
        margin-top: 20%;
        max-width: 90vw;
        max-height: 70vh;
        margin: 28% auto 0 auto;
        z-index: 1;
    }
    .banner-box {
        background-color: rgba(0, 0, 0, 0.75);
        padding: 20px 15px;
        border-radius: 12px;
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.6);
        opacity: 0;
        animation: fadeInBanner 1s ease-out forwards;
        animation-delay: 1s;
        max-height: 75vh;
        max-width: 98vw;
        z-index: 2;
        /*overflow-y: auto;*/
        overflow-y: scroll;
        scrollbar-width: none;
    }
    .feature-list {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin-bottom: 16px;
        gap: 10px;
        font-size: 1.5em;
        color: #d3d3d3;
    }
    .feature-item {
        background: rgba(0, 0, 0, 0.5);
        padding: 10px;
        border-radius: 8px;
        text-align: left;
        display: flex;
        align-items: center;
        gap: 10px;
        min-width: 1vw;
        max-width: 90vw;
    }
    .feature-item span {
        font-weight: bold;
        color: #ffdd57;
        text-align: left;
        min-width: 30vw;
        max-width: 50vw;  
    }
    .title {
        font-size: clamp(26px, 4vw, 30px);
        margin-bottom: 8px;
        color: #fff;
        text-align: center;
    }
    .subtitle {
        font-size: clamp(22px, 2vw, 24x);
        color: #e0b96a;
        margin-bottom: 16px;
        text-align: center;
    }
    .description, .launch {
        font-size: clamp(18px, 4vw, 22px);
        margin-bottom: 16px;
        text-align: center;
        color: #fff;
    }
    .banner-landscape {
        justify-content: center;
        display: flex;
        align-items: center;
        text-align: center;
        position: relative;
        margin: 10% auto 0 auto;
        z-index: 1;
    }
    .banner-box-landscape {
        /*background-image: url(./parchmentLandscape3.svg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        transform: rotate(90deg);*/
        background-color: rgba(0, 0, 0, 0.75);
        padding: 15px 10px;
        border-radius: 12px;
        text-align: center;
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.6);
        opacity: 0;
        animation: fadeInBanner 1s ease-out forwards;
        animation-delay: 1s;
        /*overflow-y: auto;*/
        overflow-y: scroll;
        scrollbar-width: none;
        z-index: 2;
        max-height: 72vh;
        max-width: 90vw;
    }
    .feature-list-landscape {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin-bottom: 16px;
        gap: 10px;
        font-size: 1.5em;
        color: #d3d3d3;
    }
    .feature-item-landscape {
        background: rgba(0, 0, 0, 0.5);
        padding: 10px;
        border-radius: 8px;
        text-align: left;
        display: flex;
        align-items: center;
        gap: 10px;
        min-width: 1vw;
        max-width: 90vw;
    }
    
    .feature-item-landscape span {
        font-weight: bold;
        color: #ffdd57;
        text-align: left;
        min-width: 44vw;
        max-width: 50vw;  
    }

    .title-landscape {
        font-size: clamp(26px, 4vw, 30px);
        margin-bottom: 0px;
        color: #fff;
    }
    
    .subtitle-landscape {
        font-size: clamp(22px, 2vw, 24x);
        color: #e0b96a;
        margin-bottom: 0px;
    }
    
    .description-landscape, .launch-landscape {
        font-size: clamp(18px, 4vw, 22px);
        margin-bottom: 12px;
        text-align: center;
        color: #fff;
    }
}
.banner-box-landscape::-webkit-scrollbar {
    display: none; /* Hides scrollbar in WebKit browsers */
}
.banner-box::-webkit-scrollbar {
    display: none; /* Hides scrollbar in WebKit browsers */
}

.app-container {
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100vh;
    width: 100dvw;
    height: 100dvh;
    display: flex;
    justify-content: center;
    object-fit: cover;
    align-items: center;
    position: relative;
}

.portrait-container, .landscape-container {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    object-fit: cover;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.background-image {
    width: 100%;
    height: 100%;
    margin: 0;
    object-fit: cover;
    position: absolute;
    z-index: -1;
}

.button-group {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-top: 15px;
}

.cta-button {
    margin:20px auto 0;
    background-color: #e07a5f;
  padding: 8px 16px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(184, 108, 87, 0.6);
  display: flex;
  border: none;
  outline: none;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  z-index: 3;
  -webkit-appearance: none; /* Removes default styling for WebKit browsers */
  appearance: none;
}

.cta-button:hover {
    background-color: #b36144;
    box-shadow: 0 6px 15px rgba(224, 122, 95, 0.8);
}
.cta-button:focus {
    outline: none;
}

.overlay-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
}
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}
.overlay-content {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 12px;
    min-width: 1vw;
    max-width: 90vw;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
    color: #333;
    text-align: center;
}
.overlay.hidden {
    display: none;
}

.close-btn {
    background-color: #e07a5f;
    color: #fff;
    padding: 8px 16px;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 15px;
    border: none;
    outline: none;
}

.close-btn:hover {
    background-color: #ff9e7b;
}

.app-header {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    z-index: 3;
  }
  
  .login-button-container {
    display: flex;
    justify-content: flex-end;
  }
  
  .login-button {
    
    background-color: #ff9e7b;
    color: white;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
  }
  
  .login-button:hover {
    background-color: #e07a5f;
  }
  
@keyframes fadeInBanner {
    to { opacity: 1; }
}
.dragon-container.portrait.mobile {
    top: 2%;
    left: 0;
    width: 80px;
    height: 80px;
    position: absolute;
    z-index: 1;
    animation: dragonMovePortraitMobile 5s linear infinite;
}

/* Mobile Landscape */
.dragon-container.landscape.mobile {
    top: 10px;
    left: 0;
    width: 70px;
    height: 70px;
    position: absolute;
    z-index: 1;
    animation: dragonMoveLandscapeMobile 8s linear infinite;
}

/* Computer Portrait */
.dragon-container.portrait.computer {
    top: 2%;
    left: 40%;
    width: 70px;
    height: 70px;
    position: absolute;
    perspective: 1000px;
    transform: translate(-50%, -50%);
    animation: dragonFlightComputer 7s linear infinite;
}

/* Computer Landscape */
.dragon-container.landscape.computer {
    top: 10px;
    left: 50%;
    width: 70px;
    height: 70px;
    position: absolute;
    perspective: 1000px;
    transform: translate(-50%, -50%);
    z-index: 1;
    animation: dragonFlightComputer 7s linear infinite;
}

@keyframes dragonMovePortraitMobile {
    0% { transform: translateX(0) scaleX(-1); }
    50% { transform: translateX(calc(100vw - 80px)) scaleX(-1); }
    50.01% { transform: translateX(calc(100vw - 80px)) scaleX(1); }
    100% { transform: translateX(0) scaleX(1); }
}

@keyframes dragonMoveLandscapeMobile {
    0% { transform: translateX(0) scaleX(-1); }
    50% { transform: translateX(calc(100vw - 100px)) scaleX(-1); }
    50.01% { transform: translateX(calc(100vw - 100px)) scaleX(1); }
    100% { transform: translateX(0) scaleX(1); }
}

@keyframes dragonFlightComputer {
    0% { transform: rotateY(360deg) translateZ(150px); opacity: 1; }
    26% { transform: rotateY(240deg) translateZ(150px); opacity: 1; }
    31% { transform: rotateY(220deg) translateZ(150px); opacity: 0.3; }
    36% { transform: rotateY(180deg) translateZ(150px); opacity: 0; }
    64% { transform: rotateY(150deg) translateZ(150px); opacity: 0; }
    65% { transform: rotateY(140deg) translateZ(150px); opacity: 0.3; }
    70% { transform: rotateY(120deg) translateZ(150px); opacity: 1; }
    100% { transform: rotateY(0deg) translateZ(150px); opacity: 1; }

}
.dragon {
    width: 100%;
}