body, html {
    font-family: 'Merriweather', Arial, sans-serif;
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100vw;
    width: 100dvw;
    height: 100dvh;
    overflow-x: hidden;
    color: #000000;
    object-fit: cover;
}
